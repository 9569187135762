import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ce291a0 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _39268293 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _b0f44648 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _60856863 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _5b3b5d5c = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _3f95a3a2 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _6a036041 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _2101aa8e = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _48cf0b32 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _33a83b91 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _3c56d28e = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _83d371ac = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _085e3f39 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _bd2a6f22 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _2915c0f3 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _0a6c5c92 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _5f3ce6fa = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _670e81ce = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _169da355 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _8d89589c = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _550988c0 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _04a39b22 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _838fc354 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _4ef63dac = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _b2152c26 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _cf005ce0 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _2420280b = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _6e45017a = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _0c28212a = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _47649b2d = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _8c5ffb7c = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _1cd79066 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _0a461c42 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _1cce7d6a = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _1850ef4b = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _4d6d900e = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _71aded60 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _16f707dd = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _afe26f90 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _6340a172 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _1ce291a0,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _39268293,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _b0f44648,
    name: "help"
  }, {
    path: "/verify-email",
    component: _60856863,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _5b3b5d5c,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _3f95a3a2,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _6a036041,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _2101aa8e,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _48cf0b32,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _33a83b91,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _3c56d28e,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _83d371ac,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _085e3f39,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _bd2a6f22,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _2915c0f3,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _0a6c5c92,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _5f3ce6fa,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _670e81ce,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _169da355,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _8d89589c,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _550988c0,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _04a39b22,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _838fc354,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _4ef63dac,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _b2152c26,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _cf005ce0,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _2420280b,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _6e45017a,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _0c28212a,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _47649b2d,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _8c5ffb7c,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _1cd79066,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _0a461c42,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _1cce7d6a,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _1850ef4b,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _4d6d900e,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _71aded60,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _16f707dd,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _afe26f90,
    name: "blog-slug"
  }, {
    path: "/",
    component: _6340a172,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
